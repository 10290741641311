import {
  BaseStepIdEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
  StepConfig,
  StepTypeEnum,
} from 'shared-domain';
import { PersonalDetails } from '../app/components/personal-details';

export const stepsConfig: StepConfig[] = [
  {
    id: 'disclaimer',
    type: StepTypeEnum.text,
  },
  {
    id: BaseStepIdEnum.individual_edit,
  },
  {
    id: 'personal_details',
    type: StepTypeEnum.custom_component,
    component: PersonalDetails,
  },
  {
    id: 'origin_funds',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'legit_origin_funds',
          type: FieldTypeEnum.checkbox,
          isRequired: true,
          isEnabled: true,
          options: ['true'],
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'origin_funds',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: [
            'professional_income',
            'holding_revenue',
            'sale_of_securities',
            'sales_of_property',
            'inheritance_donation',
            'other_source',
            'legal_entity',
            'savings',
          ],
          hasOtherOption: false,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
      ],
    },
  },
];
