import { useMemo } from 'react';
import { Box, Button, VStack, Input } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  GroupController,
  Radio,
  CountrySelect,
  Checkbox,
} from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const tax_residency_country = 'tax_residency_country';
const tax_id = 'tax_id';
const us_tax_payer_status = 'us_tax_payer_status';
const is_us_tax_exempt = 'is_us_tax_exempt';
const are_you_accredited = 'are_you_accredited';
const accredited_status = 'accredited_status';
const accredited_status_options = ['net_worth', 'income', 'active_licence'];
const are_you_a_pep = 'are_you_a_pep';

const validationSchema = Yup.object({
  [tax_residency_country]: Yup.string().required().label('This'),
  [tax_id]: Yup.string().required().label('This'),
  [us_tax_payer_status]: Yup.string()
    .oneOf(['true', 'false'])
    .required()
    .label('This'),
  [is_us_tax_exempt]: Yup.string()
    .oneOf(['true', 'false'])
    .when(us_tax_payer_status, {
      is: (val: string) => val === 'true',
      then: (schema) => schema.required().label('This'),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    }),
  [are_you_accredited]: Yup.string()
    .oneOf(['true', 'false'])
    .when(us_tax_payer_status, {
      is: (val: string) => val === 'true',
      then: (schema) =>
        schema
          .required()
          .test(
            'is-accredited',
            'Securities offered on Roundtable have not been registered under the US Securities ACT. As such, US investors must be accredited investors.',
            (value) => value === 'true',
          ),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    }),
  [accredited_status]: Yup.array(
    Yup.mixed().oneOf(accredited_status_options),
  ).when(are_you_accredited, {
    is: (val: string) => val === 'true',
    then: (schema) => schema.min(1).required().label('this'),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [are_you_a_pep]: Yup.string()
    .oneOf(['true', 'false'])
    .required()
    .label('This'),
});

export const PersonalDetails = () => {
  const stepId = useStepId();
  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata } = useStore();

  const defaultValues = useMemo(() => {
    return {
      [tax_residency_country]: metadata?.[tax_residency_country] || '',
      [tax_id]: metadata?.[tax_id] || '',
      [us_tax_payer_status]: metadata?.[us_tax_payer_status] || '',
      [is_us_tax_exempt]: metadata?.[is_us_tax_exempt] || '',
      [are_you_accredited]: metadata?.[are_you_accredited] || '',
      [accredited_status]: metadata?.[accredited_status] || '',
      [are_you_a_pep]: metadata?.[are_you_a_pep] || '',
    };
  }, [metadata]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCustomStepForm({ caseMetadata: formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        <GroupController
          name={tax_residency_country}
          label={t(`steps.${stepId}.${tax_residency_country}.label`)}
          isRequired={true}
          control={control}
          render={(f) => {
            return (
              <CountrySelect
                onChange={(value: string[]) => {
                  setValue(tax_residency_country, value ?? [], {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                defaultValue={f.value}
                isMulti={false}
              />
            );
          }}
        />

        <GroupController
          name={tax_id}
          label={t(`steps.${stepId}.${tax_id}.label`)}
          isRequired={true}
          control={control}
          render={(f) => {
            return <Input type="text" maxW="400px" {...f} />;
          }}
        />

        <GroupController
          name={us_tax_payer_status}
          label={t(`steps.${stepId}.${us_tax_payer_status}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={us_tax_payer_status}
              onChange={(value: string) => {
                resetField(are_you_accredited);
                setValue(us_tax_payer_status, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['true', 'false']}
              defaultValue={f.value}
            />
          )}
        />

        {watch(us_tax_payer_status) === 'true' && (
          <>
            <GroupController
              name={is_us_tax_exempt}
              label={t(`steps.${stepId}.${is_us_tax_exempt}.label`)}
              isRequired={true}
              control={control}
              render={(f) => (
                <Radio
                  stepId={stepId}
                  name={is_us_tax_exempt}
                  onChange={(values: string) => {
                    setValue(is_us_tax_exempt, values ?? '', {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  options={['true', 'false']}
                  defaultValue={f.value}
                />
              )}
            />
            <GroupController
              name={are_you_accredited}
              label={t(`steps.${stepId}.${are_you_accredited}.label`)}
              isRequired={true}
              control={control}
              render={(f) => (
                <Radio
                  stepId={stepId}
                  name={are_you_accredited}
                  onChange={(value: string) => {
                    resetField(accredited_status);
                    setValue(are_you_accredited, value ?? '', {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  options={['true', 'false']}
                  defaultValue={f.value}
                />
              )}
            />
          </>
        )}

        {watch(are_you_accredited) === 'true' && (
          <GroupController
            name={accredited_status}
            label={t(`steps.${stepId}.${accredited_status}.label`)}
            isRequired={false}
            control={control}
            render={(f) => (
              <Checkbox
                stepId={stepId}
                name={accredited_status}
                onChange={(values: string[]) => {
                  setValue(accredited_status, values ?? [], {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                options={accredited_status_options}
                defaultValue={f.value}
              />
            )}
          />
        )}

        <GroupController
          name={are_you_a_pep}
          label={t(`steps.${stepId}.${are_you_a_pep}.label`)}
          helper={t(`steps.${stepId}.${are_you_a_pep}.helper`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={are_you_a_pep}
              onChange={(value: string) => {
                setValue(are_you_a_pep, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['true', 'false']}
              defaultValue={f.value}
            />
          )}
        />

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
